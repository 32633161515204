import React from "react"
import { connect } from "react-redux"
import Highcharts from "highcharts/highstock"
import HC_more from "highcharts/highcharts-more"
import dumbbell from "highcharts/modules/dumbbell"
import EmptyChartIndicator from "../../../common/components/emptyChartIndicator"

HC_more(Highcharts)
dumbbell(Highcharts)

class CampaignPopupPerformanceByKeywordChart extends React.Component {
  constructor(props) {
    super(props)
    this.chartRef = React.createRef()
    this.chart = null
  }

  componentDidMount() {
    this.drawChart()
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.drawChart()
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.destroy()
    }
  }

  transformDataForChart(data) {
    const seriesData = []
    const keywords = Object.keys(data)

    // Loop through each keyword
    keywords.forEach((keyword) => {
      const keywordData = data[keyword]

      const series = {
        name: keyword,
        data: [],
        showCheckbox: true, // Enable checkbox for each series
      }

      Object.keys(keywordData).forEach((day) => {
        const entry = keywordData[day]
        // Push an array with the x (day) and y (clicks) values
        series.data.push([new Date(day).getTime(), entry.clicks])
      })

      seriesData.push(series)
    })

    return seriesData
  }

  drawChart() {
    const { data } = this.props

    if (!data || Object.keys(data).length === 0 || !this.chartRef.current) {
      return
    }

    const transformedData = this.transformDataForChart(data)

    const colors = [
      "#176FF3",
      "#289BF3",
      "#00B5CE",
      "#64DCEC",
      "#118340",
      "#00C752",
      "#6DAC3B",
      "#00B390",
      "#F70F32",
      "#FF5F0C",
      "#FE8C23",
      "#ECB336",
      "#65118D",
      "#A537B3",
      "#F329A2",
      "#5E29F3",
    ]

    this.chart = Highcharts.chart(this.chartRef.current, {
      chart: {
        type: "area",
        events: {
          load() {
            // Check all checkboxes on load
            this.series.forEach((series) => {
              series.checkbox.checked = true
              series.selected = true
            })
          },
        },
      },
      credits: {
        enabled: false,
      },
      colors: colors,
      title: {
        text: null,
      },
      subtitle: {
        text: null,
      },
      xAxis: {
        type: "datetime",
        title: {
          text: "Date",
        },
      },
      yAxis: {
        title: {
          text: "Keyword",
        },
        labels: {
          format: "{value}",
        },
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        split: true,
      },
      plotOptions: {
        series: {
          stacking: "percent",
          marker: {
            enabled: false,
          },
          events: {
            checkboxClick(event) {
              this.setVisible(event.checked)
            },
          },
        },
        area: {
          stacking: "percent",
          marker: {
            enabled: false,
          },
        },
      },
      legend: {
        enabled: true,
        useHTML: true,
        symbolWidth: 0.001,
        symbolRadius: 0.001,
        symbolHeight: 0.001,
        itemMarginTop: 2,
        itemMarginBottom: 2,
        y: -30,
        x: 10,
        labelFormatter: function () {
          return '<div class="legend-item-text-keyword">' + this.name + "</div>"
        },
      },
      series: transformedData,
    })
  }

  render() {
    const { hasData } = this.props

    return (
      <div className="campaign-popup-chart-wrapper">
        {!hasData && <EmptyChartIndicator />}
        <div ref={this.chartRef} className="campaign-popup-chart" />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    hasData: state.campaignPopupV2.performanceByKeyword.hasData,
    data: state.campaignPopupV2.performanceByKeyword.dataForTable,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByKeywordChart)
