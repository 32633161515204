import React from "react"
import Moment from "moment"
import { connect } from "react-redux"
import CampaignsConsts from "../../../campaignsV2/campaignsConsts"
import { campaignPopupSortPerformanceList, updatePerformanceByDayPopupPage } from "../../campaignPopupActions"
import Consts from "../../../app/consts"
import Table from "../../../common/components/table/table"
import { isUnknownCampaignTrafficSource } from "../../../utils/campaignUtilsV2"
import CampaignROI from "../../../common/components/campaignRoi"
import TableConsts from "../../../common/components/table/tableConsts"
import { isNullOrUndefined } from "../../../utils/funcUtils"
import Pagination from "../../../common/components/pagination"
import articlesTypeConsts from "../../../articles/components/articlesType"

class CampaignPopupPerformanceByDayList extends React.Component {
  columns = CampaignsConsts.LIST_COLUMNS

  sortDaysBy = (column) => {
    this.props.dispatch(campaignPopupSortPerformanceList(column, "performanceByDay"))
  }

  getHeader = () => {
    let columns = this.columns
    return {
      stickyCells: [],
      cells: [
        {
          fieldName: columns.date.fieldName,
          value: columns.date.title,
          clickable: true,
        },
        {
          fieldName: columns.data_columns.visits.fieldName,
          value: columns.data_columns.visits.title,
          containerProps: {
            "data-tip": columns.data_columns.visits.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.clicks.fieldName,
          value: columns.data_columns.clicks.title,
          containerProps: {
            "data-tip": columns.data_columns.clicks.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.profit.fieldName,
          value: columns.data_columns.profit.title,
          containerProps: {
            "data-tip": columns.data_columns.profit.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.roi.fieldName,
          value: columns.data_columns.roi.title,
          containerProps: {
            "data-tip": columns.data_columns.roi.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.dailySpent.fieldName,
          value: columns.data_columns.dailySpent.title,
          containerProps: {
            "data-tip": columns.data_columns.dailySpent.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.revenue.fieldName,
          value: columns.data_columns.revenue.title,
          containerProps: {
            "data-tip": columns.data_columns.revenue.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.revenueEcom.fieldName,
          value: columns.data_columns.revenueEcom.title,
          containerProps: {
            "data-tip": columns.data_columns.revenueEcom.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.data_columns.ecomYieldingClicks.fieldName,
          value: columns.data_columns.ecomYieldingClicks.title,
          containerProps: {
            "data-tip": columns.data_columns.ecomYieldingClicks.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.data_columns.keywordImpressions.fieldName,
          value: columns.data_columns.keywordImpressions.title,
          containerProps: {
            "data-tip": columns.data_columns.keywordImpressions.tooltip,
          },
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.keywordImpressionRate.fieldName,
          value: columns.data_columns.keywordImpressionRate.title,
          containerProps: {
            "data-tip": columns.data_columns.keywordImpressionRate.tooltip,
          },
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.searchesRate.fieldName,
          value: columns.data_columns.searchesRate.title,
          containerProps: {
            "data-tip": columns.data_columns.searchesRate.tooltip,
          },
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.searchesFunnelRpm.fieldName,
          value: columns.data_columns.searchesFunnelRpm.title,
          containerProps: {
            "data-tip": columns.data_columns.searchesFunnelRpm.tooltip,
          },
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },

        {
          fieldName: columns.data_columns.costPerClick.fieldName,
          value: columns.data_columns.costPerClick.title,
          containerProps: {
            "data-tip": columns.data_columns.costPerClick.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.visitValue.fieldName,
          value: columns.data_columns.visitValue.title,
          containerProps: {
            "data-tip": columns.data_columns.visitValue.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.pagesPerVisit.fieldName,
          value: columns.data_columns.pagesPerVisit.title,
          containerProps: {
            "data-tip": columns.data_columns.pagesPerVisit.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.bounceRate.fieldName,
          value: columns.data_columns.bounceRate.title,
          containerProps: {
            "data-tip": columns.data_columns.bounceRate.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.rpm.fieldName,
          value: columns.data_columns.rpm.title,
          containerProps: {
            "data-tip": columns.data_columns.rpm.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.rpc.fieldName,
          value: columns.data_columns.rpc.title,
          containerProps: {
            "data-tip": columns.data_columns.rpc.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.rsocYieldingClicks.fieldName,
          value: columns.data_columns.rsocYieldingClicks.title,
          containerProps: {
            "data-tip": columns.data_columns.rsocYieldingClicks.tooltip,
          },
          clickable: true,
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.data_columns.ctr.fieldName,
          value: columns.data_columns.ctr.title,
          containerProps: {
            "data-tip": columns.data_columns.ctr.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.results.fieldName,
          value: columns.data_columns.results.title,
          containerProps: {
            "data-tip": columns.data_columns.results.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.costPerResult.fieldName,
          value: columns.data_columns.costPerResult.title,
          containerProps: {
            "data-tip": columns.data_columns.costPerResult.tooltip,
          },
          clickable: true,
        },
        {
          fieldName: columns.data_columns.resultsRate.fieldName,
          value: columns.data_columns.resultsRate.title,
          containerProps: {
            "data-tip": columns.data_columns.resultsRate.tooltip,
          },
          clickable: true,
        },
      ],
    }
  }

  getSummaryRow = (isUnknownTrafficSource) => {
    let columns = this.columns.data_columns
    let summary = this.props.summaryRow
    let campaignRoiValue = TableConsts.EMPTY_CELL

    if (!isUnknownTrafficSource) {
      campaignRoiValue = <CampaignROI roi={summary.roi} siteId={this.props.campaign.site_id} />
    }

    return {
      cssClasses: ["summary-row"],
      key: "summary no-export",
      stickyCells: [],
      cells: [
        {
          fieldName: "total",
          value: "Total",
        },
        {
          fieldName: columns.visits.fieldName,
          value: columns.visits.defaultFormat(summary.visits),
        },
        {
          fieldName: columns.clicks.fieldName,
          value: columns.clicks.defaultFormat(summary.clicks),
        },
        {
          fieldName: columns.profit.fieldName,
          value: columns.profit.defaultFormat(summary.profit),
        },
        {
          fieldName: columns.roi.fieldName,
          value: campaignRoiValue,
        },
        {
          fieldName: "Spend",
          value: isNullOrUndefined(summary.daily_spent)
            ? TableConsts.EMPTY_CELL
            : columns.dailySpent.defaultFormat(summary.daily_spent),
        },
        {
          fieldName: "Revenue",
          value: isNullOrUndefined(summary.revenue)
            ? TableConsts.EMPTY_CELL
            : columns.revenue.defaultFormat(summary.revenue),
        },
        {
          fieldName: columns.revenueEcom.fieldName,
          value: columns.revenueEcom.defaultFormat(summary.revenue_ecom),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.ecomYieldingClicks.fieldName,
          value: columns.ecomYieldingClicks.defaultFormat(summary.ecom_yielding_clicks),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.keywordImpressions.fieldName,
          value: columns.keywordImpressions.defaultFormat(summary.keyword_impressions),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.keywordImpressionRate.fieldName,
          value: columns.keywordImpressionRate.defaultFormat(summary.keyword_impression_rate),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.searchesRate.fieldName,
          value: columns.searchesRate.defaultFormat(summary.searches_rate),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.searchesFunnelRpm.fieldName,
          value: columns.searchesFunnelRpm.defaultFormat(summary.searches_funnel_rpm),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },

        {
          fieldName: columns.costPerClick.fieldName,
          value: columns.costPerClick.defaultFormat(summary.cost_per_click),
        },
        {
          fieldName: columns.visitValue.fieldName,
          value: columns.visitValue.defaultFormat(summary.visit_value),
        },
        {
          fieldName: "pagesPerVisit",
          value: columns.pagesPerVisit.defaultFormat(summary.pages_per_visit),
          cssClasses: ["summary-row"],
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.bounceRate.fieldName,
          value: columns.bounceRate.defaultFormat(summary.bounce_rate),
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "rpm",
          value: isNullOrUndefined(summary.rpm) ? TableConsts.EMPTY_CELL : columns.rpm.defaultFormat(summary.rpm),
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.rpc.fieldName,
          value: columns.rpc.defaultFormat(summary.rpc),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.rsocYieldingClicks.fieldName,
          value: columns.rsocYieldingClicks.defaultFormat(summary.rsoc_yielding_clicks),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.ctr.fieldName,
          value: isUnknownTrafficSource ? TableConsts.EMPTY_CELL : columns.ctr.defaultFormat(summary.ctr),
        },
        {
          fieldName: "results",
          value: isNullOrUndefined(summary.results)
            ? TableConsts.EMPTY_CELL
            : columns.results.defaultFormat(summary.results),
        },
        {
          fieldName: columns.costPerResult.fieldName,
          value: isNullOrUndefined(summary.cost_per_result)
            ? TableConsts.EMPTY_CELL
            : columns.costPerResult.defaultFormat(summary.cost_per_result),
        },
        {
          fieldName: columns.resultsRate.fieldName,
          value: isNullOrUndefined(summary.results_rate)
            ? TableConsts.EMPTY_CELL
            : columns.resultsRate.defaultFormat(summary.results_rate),
        },
      ],
    }
  }

  getRow = (day) => {
    let columns = this.columns.data_columns
    return {
      stickyCells: [],
      key: day.date,
      cells: [
        {
          fieldName: "date",
          rawValue: day.date,
          value: (
            <>
              <span>
                {this.props.header
                  ? this.props.header
                  : Moment(day.date, Consts.SERVER_DATE_FORMAT).format(Consts.CLIENT_DATE_FORMAT)}
              </span>
              <span className="week-day">
                {Moment(day.date, Consts.SERVER_DATE_FORMAT).format(Consts.CLIENT_DAY_NAME_FORMAT) +
                  (Moment(day.date, Consts.SERVER_DATE_FORMAT).isSame(Moment(), "day") ? " (So Far)" : "")}
              </span>
            </>
          ),
          cssClasses: ["date-cell", "font-semibold"],
        },
        {
          fieldName: "visits",
          rawValue: day.visits,
          value: isNullOrUndefined(day.visits) ? TableConsts.EMPTY_CELL : columns.visits.defaultFormat(day.visits),
        },
        {
          fieldName: "clicks",
          value: isNullOrUndefined(day.clicks) ? TableConsts.EMPTY_CELL : columns.clicks.defaultFormat(day.clicks),
        },
        {
          fieldName: "estimated_profit",
          rawValue: day.profit,
          value: columns.profit.defaultFormat(day.profit),
        },
        {
          fieldName: "roi",
          rawValue: day.roi,
          value: isNullOrUndefined(day.roi) ? (
            TableConsts.EMPTY_CELL
          ) : (
            <CampaignROI roi={day.roi} siteId={this.props.campaign.site_id} />
          ),
          cssClasses: ["font-bold"],
        },
        {
          fieldName: "daily_spent",
          rawValue: day.daily_spent,
          value: isNullOrUndefined(day.daily_spent)
            ? TableConsts.EMPTY_CELL
            : columns.dailySpent.defaultFormat(day.daily_spent),
        },
        {
          fieldName: "revenue",
          rawValue: day.revenue,
          value: isNullOrUndefined(day.revenue) ? TableConsts.EMPTY_CELL : columns.revenue.defaultFormat(day.revenue),
        },
        {
          fieldName: columns.revenueEcom.fieldName,
          rawValue: day.revenue_ecom,
          value: isNullOrUndefined(day.revenue_ecom)
            ? TableConsts.EMPTY_CELL
            : columns.revenueEcom.defaultFormat(day.revenue_ecom),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.ecomYieldingClicks.fieldName,
          rawValue: day.ecom_yielding_clicks,
          value: isNullOrUndefined(day.ecom_yielding_clicks)
            ? TableConsts.EMPTY_CELL
            : columns.ecomYieldingClicks.defaultFormat(day.ecom_yielding_clicks),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.affiliation.name,
        },
        {
          fieldName: columns.keywordImpressions.fieldName,
          rawValue: day.keyword_impressions,
          value: isNullOrUndefined(day.keyword_impressions)
            ? TableConsts.EMPTY_CELL
            : columns.keywordImpressions.defaultFormat(day.keyword_impressions),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.keywordImpressionRate.fieldName,
          rawValue: day.keyword_impression_rate,
          value: isNullOrUndefined(day.keyword_impression_rate)
            ? TableConsts.EMPTY_CELL
            : columns.keywordImpressionRate.defaultFormat(day.keyword_impression_rate),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.searchesRate.fieldName,
          rawValue: day.searches_rate,
          value: isNullOrUndefined(day.searches_rate)
            ? TableConsts.EMPTY_CELL
            : columns.searchesRate.defaultFormat(day.searches_rate),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: columns.searchesFunnelRpm.fieldName,
          rawValue: day.searches_funnel_rpm,
          value: isNullOrUndefined(day.searches_funnel_rpm)
            ? TableConsts.EMPTY_CELL
            : columns.searchesFunnelRpm.defaultFormat(day.searches_funnel_rpm),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "cost_per_click",
          rawValue: day.cost_per_click,
          value: columns.costPerClick.defaultFormat(day.cost_per_click),
        },
        {
          fieldName: "visit_value",
          rawValue: day.visit_value,
          value: isNullOrUndefined(day.visit_value)
            ? TableConsts.EMPTY_CELL
            : columns.visitValue.defaultFormat(day.visit_value),
        },
        {
          fieldName: "pps",
          rawValue: day.pps,
          value: isNullOrUndefined(day.pages_per_visit)
            ? TableConsts.EMPTY_CELL
            : columns.pagesPerVisit.defaultFormat(day.pages_per_visit),
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "bounce_rate",
          rawValue: day.bounce_rate,
          value: isNullOrUndefined(day.bounce_rate)
            ? TableConsts.EMPTY_CELL
            : columns.bounceRate.defaultFormat(day.bounce_rate),
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "rpm",
          value: isNullOrUndefined(day.rpm) ? TableConsts.EMPTY_CELL : columns.rpm.defaultFormat(day.rpm),
          isVisible: this.props.campaign.post_style !== articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "rpc",
          rawValue: day.rpc,
          value: isNullOrUndefined(day.rpc) ? TableConsts.EMPTY_CELL : columns.rpc.defaultFormat(day.rpc),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },
        {
          fieldName: "rsoc_yielding_clicks",
          rawValue: day.rsoc_yielding_clicks,
          value: isNullOrUndefined(day.rsoc_yielding_clicks)
            ? TableConsts.EMPTY_CELL
            : columns.rsocYieldingClicks.defaultFormat(day.rsoc_yielding_clicks),
          isVisible: this.props.campaign.post_style === articlesTypeConsts.ads_for_search.name,
        },

        {
          fieldName: "ctr",
          rawValue: day.ctr,
          value: isNullOrUndefined(day.ctr) ? TableConsts.EMPTY_CELL : columns.ctr.defaultFormat(day.ctr),
        },
        {
          fieldName: "results",
          rawValue: day.results,
          value: isNullOrUndefined(day.results) ? TableConsts.EMPTY_CELL : columns.results.defaultFormat(day.results),
        },
        {
          fieldName: "cost_per_result",
          value: isNullOrUndefined(day.cost_per_result)
            ? TableConsts.EMPTY_CELL
            : columns.costPerResult.defaultFormat(day.cost_per_result),
        },
        {
          fieldName: "results_rate",
          value: isNullOrUndefined(day.results_rate)
            ? TableConsts.EMPTY_CELL
            : columns.resultsRate.defaultFormat(day.results_rate),
        },
      ],
    }
  }

  getRows = (usePagination = true) => {
    let rows = []
    let currentPage = this.props.currentPage
    let itemsPerPage = this.props.itemsPerPage
    let firstItemsIndex = (currentPage - 1) * itemsPerPage
    let isUnknownTrafficSource = isUnknownCampaignTrafficSource(this.props.campaign)
    let days = this.props.performanceByDay

    if (usePagination) {
      days = []

      for (
        let i = firstItemsIndex;
        i < this.props.currentPage * this.props.itemsPerPage && i < this.props.performanceByDay.length;
        i++
      ) {
        let day = this.props.performanceByDay[i]
        days.push(day)
      }
    }

    if (this.props.performanceByDay.length > 0) {
      rows.push(this.getSummaryRow(isUnknownTrafficSource))

      days.forEach((day) => {
        rows.push(this.getRow(day))
      })
    }

    return rows
  }

  getAllRows = () => {
    return this.getRows(false)
  }

  render() {
    let header = this.getHeader()
    let rows = this.getRows()
    let pagination = null

    let csvFileName = "campaign-performance-by-day-" + this.props.campaign.campaign_id + "-"

    csvFileName +=
      this.props.datePickerDates.startDate.format(Consts.SERVER_DATE_FORMAT) +
      "-" +
      this.props.datePickerDates.endDate.format(Consts.SERVER_DATE_FORMAT)

    if (this.props.performanceByDay.length > this.props.itemsPerPage) {
      pagination = (
        <Pagination
          itemsArr={this.props.performanceByDay ? this.props.performanceByDay.length : this.props.performanceByDay}
          currentPage={this.props.currentPage}
          itemsPerPage={this.props.itemsPerPage}
          updatePage={updatePerformanceByDayPopupPage}
        />
      )
    }

    return (
      <div>
        <Table
          header={header}
          rows={rows}
          sort={{
            sortBy: this.props.sortBy,
            sortDirection: this.props.sortDirection,
            sortHandler: this.sortDaysBy,
          }}
          scrolling={{
            containerClass: ".campaign-popup-container",
            includeNavigationBar: false,
          }}
          isExportableToCsv
          csvFileName={csvFileName}
          ref={(table) => {
            this.table = table
          }}
          getAllRows={this.getAllRows}
          cssClasses={["campaign-popup-list"]}
        />
        {pagination}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    performanceByDay: state.campaignPopupV2.performanceByDay.dataForTable,
    sortBy: state.campaignPopupV2.performanceByDay.sortBy,
    sortDirection: state.campaignPopupV2.performanceByDay.sortDirection,
    summaryRow: state.campaignPopupV2.performanceByDay.summaryRow,
    datePickerDates: {
      startDate: state.campaignPopupV2.datePickerDates.startDate,
      endDate: state.campaignPopupV2.datePickerDates.endDate,
    },
    campaign: state.campaignPopupV2.campaign,
    itemsPerPage: state.campaignPopupV2.performanceByDay.itemsPerPage,
    currentPage: state.campaignPopupV2.performanceByDay.currentPage,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByDayList)
