import React from "react"
import { IndexRedirect, IndexRoute, Route, Router } from "react-router"
import ReactGA from "react-ga"
import { Provider } from "react-redux"
import Loadable from "react-loadable"
import { hot } from "react-hot-loader/root"
import { history, store } from "./store"
import App from "./app"
import { isCampaignPopupRelatedLocationStateV2 } from "../utils/campaignUtilsV2"
import QueryParametersPasser from "../common/components/queryParametersPasser"
import { checkAuth, isAuthenticated } from "../utils/authUtils"
import { redirectToUrl } from "../utils/routingUtils"
import Consts from "./consts"

// Dynamically importing the route components, these components will not be loaded until they are needed (lazy loaded).
// This ensures smaller js bundles and that the user won't load code that he doesn't need during his session.
const LoginPage = Loadable({ loader: () => import("../login/loginPage"), loading: () => null })
const AdminPage = Loadable({ loader: () => import("../admin/components/adminPage"), loading: () => null })
const AdminUsersPage = Loadable({ loader: () => import("../admin/usersPage/adminUsersPage"), loading: () => null })
const AdminRolesPage = Loadable({ loader: () => import("../admin/rolesPage/adminRolesPage"), loading: () => null })
const AdminMessagesPage = Loadable({
  loader: () => import("../admin/messagesPage/adminMessagesPage"),
  loading: () => null,
})
const AdminSitesPage = Loadable({ loader: () => import("../admin/sitesPage/adminSitesPage"), loading: () => null })
const AdminNetworksPage = Loadable({
  loader: () => import("../admin/networksPage/adminNetworksPage"),
  loading: () => null,
})
const SettingsPage = Loadable({ loader: () => import("../settings/settingsPage"), loading: () => null })
const Profile = Loadable({ loader: () => import("../settings/sections/profile/profile"), loading: () => null })
const Account = Loadable({ loader: () => import("../settings/sections/account"), loading: () => null })
const CampaignCreationSettings = Loadable({
  loader: () => import("../settings/sections/campaignCreationSettings"),
  loading: () => null,
})
const WordpressAssets = Loadable({
  loader: () => import("../campaignCreation/components/wordpressAssets"),
  loading: () => null,
})
const CampaignsMainPageV2 = Loadable({ loader: () => import("../campaignsV2/campaignsMainPage"), loading: () => null })
const CampaignPopupPageV2 = Loadable({
  loader: () => import("../campaignPopupV2/campaignPopupPage"),
  loading: () => null,
})
const UnintegratedCampaignsMainPage = Loadable({
  loader: () => import("../unintegratedCampaigns/unintegratedCampaignsPage"),
  loading: () => null,
})
const AutomationsMainPage = Loadable({
  loader: () => import("../automations/automationsMainPage"),
  loading: () => null,
})
const AutomationPopupPage = Loadable({
  loader: () => import("../automationPopup/automationPopupPage"),
  loading: () => null,
})
const DashboardMainPage = Loadable({
  loader: () => import("../dashboard/dashboardPage"),
  loading: () => null,
})
const PolicyMainPage = Loadable({
  loader: () => import("../policyReview/policyPage"),
  loading: () => null,
})
const DraftsMainPage = Loadable({
  loader: () => import("../drafts/draftsPage"),
  loading: () => null,
})
const ArticlesMainPage = Loadable({
  loader: () => import("../articles/articlesPage"),
  loading: () => null,
})
const ArticlesRsocMainPage = Loadable({
  loader: () => import("../articles/articlesRsocPage"),
  loading: () => null,
})

function skipIfLoggedIn() {
  if (isAuthenticated()) {
    redirectToUrl("/")
  }
}

function onPathChange() {
  if (process.env.ENV === "prod") {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  // Resetting scroll position when navigating to a different page
  // (unless we're on a campaign popup or returning from it)
  if (!isCampaignPopupRelatedLocationStateV2()) {
    window.scrollTo(0, 0)
  }
}

// build the router
const RouterComponent = () => (
  <Provider store={store}>
    <Router onUpdate={onPathChange} history={history}>
      <Route path="assets" component={WordpressAssets} />
      <Route path="/" component={App}>
        <Route path="login" component={LoginPage} onEnter={skipIfLoggedIn} />

        <Route onEnter={checkAuth} onChange={checkAuth}>
          <IndexRoute component={CampaignsMainPageV2} />

          <Route path="sudo" component={AdminPage}>
            <IndexRedirect to="users" />
            <Route path="users" component={AdminUsersPage} />
            <Route path="roles" component={AdminRolesPage} />
            <Route path="messages" component={AdminMessagesPage} />
            <Route path="sites" component={AdminSitesPage} />
            <Route path="networks" component={AdminNetworksPage} />
          </Route>

          <Route path="settings" component={SettingsPage}>
            <IndexRedirect to="profile" />
            <Route path="profile" component={Profile} />
            <Route path="campaignCreationSettings" component={CampaignCreationSettings} />
            <Route path="account" component={Account} />
          </Route>

          <Route path="redirect" component={QueryParametersPasser} />

          {/* The campaign popup page route works a little differently than other routes, the following route
           is only accessible when a user directly browses to it (direct link, new tab etc.).
           All other interactions with the campaign popup are being taken care of in the <CampaignPopup> component
           that is declared in app.js */}
          {/* popup v2 */}
          <Route path="campaign/:campaignId" component={CampaignPopupPageV2} />
          <Route path="rule/:ruleId" component={AutomationPopupPage} />

          <Route path="/:networkName(/:siteName)/">
            <IndexRedirect to={"/:networkName(/:siteName)/" + Consts.CAMPAIGN_MANAGEMENT} />
            <Route path={Consts.CAMPAIGN_MANAGEMENT} component={CampaignsMainPageV2} />
            <Route path={Consts.TRAFFIC_SOURCES} component={UnintegratedCampaignsMainPage} />
            <Route path={Consts.AUTOMATION_RULES} component={AutomationsMainPage} />
            <Route path={Consts.DASHBOARD} component={DashboardMainPage} />
            <Route path={Consts.POLICY_REVIEW} component={PolicyMainPage} />
            <Route path={Consts.DRAFTS} component={DraftsMainPage} />
            <Route path={Consts.ARTICLES} component={ArticlesMainPage} />
            <Route path={Consts.ARTICLES_RSOC} component={ArticlesRsocMainPage} />
          </Route>

          <Route path="/:networkName(/:siteName)">
            <IndexRedirect to="/:networkName(/:siteName)/" />
          </Route>
        </Route>
      </Route>
    </Router>
  </Provider>
)

// export
export { isAuthenticated }
export default hot(RouterComponent)
