import React from "react"
import Moment from "moment"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router"
import { AppBar, Avatar, Menu, Drawer, MenuItem, Paper, Button } from "@material-ui/core"
import ArrowBack from "@material-ui/icons/ArrowBack"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import {
  dismissAdminNotification,
  getAllActivitiesAsync,
  getAllNotifications,
  navigationDrawerStateChange,
} from "./navigationBarActions"
import { clearNotifications, createNetworkSelected, createSiteSelected } from "../common/actions/commonActions"
import "../utils/arrayUtils"
import "../utils/numberUtils"
import AuthApi from "../api/authService"
import DrawerBody from "./components/drawerContainer"
import NavigationIcons from "./components/navigationIcons"
import Consts from "../app/consts"
import AdminNotificationMessageType from "../common/consts/adminNotificationMessageType"
import PubPlusIcon from "../resources/svgs/PubPlusIcon.svg"
import { eventsTracker } from "../api/eventsTracker"
import { isCampaignPopupLocationStateV2, isCampaignPopupOpenV2 } from "../utils/campaignUtilsV2"
import NetworkTypes from "../common/consts/networkTypes"
import { redirectToDefaultPage, redirectToUrl } from "../utils/routingUtils"
import { getPermittedSite, getPermittedNetwork } from "../utils/permissionUtils"
import { isAuthenticated } from "../utils/authUtils"
import NavigationMenu from "./components/navigationMenu"
import { isAutomationPopupLocationState, isAutomationPopupOpen } from "../utils/automationUtils"

const NETWORK_AND_SITES_PAGES = [
  Consts.CAMPAIGN_MANAGEMENT,
  Consts.TRAFFIC_SOURCES,
  Consts.AUTOMATION_RULES,
  Consts.DASHBOARD,
  Consts.POLICY_REVIEW,
  Consts.DRAFTS,
  Consts.ARTICLES,
  Consts.ARTICLES_RSOC,
]
const INDEPENDENT_PAGES = ["profile", "campaignCreationSettings", "account", "redirect", "assets"]

class NavigationBar extends React.Component {
  ADMIN_NOTIFICATION_HEIGHT = 40

  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
      anchorElMenu: null,
      anchorElArticleMenu: null,
      open: false,
      search: "",
    }

    this.rootMenuItems = []
    this.menuItemsToHtml = new Map()
  }

  closeMenu = () => {
    this.setState({
      open: false,
      search: "",
    })
  }

  handleClickCampaignManagementMenu = (event) => {
    this.setState({
      anchorElMenu: event.currentTarget,
    })
  }

  closeCampaignManagementMenu = () => {
    this.setState({
      anchorElMenu: false,
    })
  }

  handleClickArticlesMenu = (event) => {
    this.setState({
      anchorElArticleMenu: event.currentTarget,
    })
  }

  closeArticlesMenu = () => {
    this.setState({
      anchorElArticleMenu: false,
    })
  }

  updateSearch = (search) => {
    this.setState({ search })
  }

  getPageLink = () => {
    let prefix = ""
    return NETWORK_AND_SITES_PAGES.includes(this.props.currentPage) ? prefix + this.props.currentPage : ""
  }

  onNetworkSelected = (event, network, index) => {
    event.stopPropagation()
    this.closeMenu()

    // dispatching the  selected network so others can act for this change.
    this.props.dispatch(createNetworkSelected(network))

    if (NetworkTypes.SPECIAL_NETWORK_IDS.includes(network.id)) {
      redirectToUrl("/" + NetworkTypes.idToType[network.id].link + "/" + this.getPageLink())
    } else {
      redirectToUrl("/" + network.name + "/" + this.getPageLink())
    }
  }

  initRootMenu = () => {
    let menuItemsToHtml = new Map()

    // Creating an array of all the customer types according to the user's sites
    let networkGroupTypes = [...new Set(this.props.sites.map((site) => site.network.type))]
      .filter((networkGroupTypeId) => NetworkTypes.NETWORK_GROUP_TYPE_IDS.includes(networkGroupTypeId))
      .sort()

    // Showing customer types menu items only if there are at least two of them
    if (networkGroupTypes.length > 1) {
      networkGroupTypes.forEach((networkGroupTypeId) => {
        let networkGroupType = NetworkTypes.idToType[networkGroupTypeId]

        menuItemsToHtml.set(
          networkGroupType.name,
          <MenuItem
            className="dropdown-network-group-type"
            button
            key={"network-group-type-" + networkGroupType.name}
            onClick={(event) => this.onNetworkSelected(event, networkGroupType, networkGroupType.id)}
          >
            <Link to={"/" + networkGroupType.name + "/" + this.getPageLink()}>{networkGroupType.name}</Link>
          </MenuItem>
        )
      })
    }

    // Showing "All Networks" menu item only if the user is an admin or has at least 2 different networks
    if (
      this.props.user.is_admin ||
      (Object.keys(this.props.user.network_to_modules).filter((networkId) => {
        return !NetworkTypes.SPECIAL_NETWORK_IDS.includes(parseInt(networkId))
      }).length > 1 &&
        this.props.user.network_to_modules[NetworkTypes.ALL_NETWORKS.id].length > 0)
    ) {
      menuItemsToHtml.set(
        NetworkTypes.ALL_NETWORKS.name,
        <MenuItem
          className="dropdown-network"
          button
          key={NetworkTypes.ALL_NETWORKS.id}
          onClick={(event) => this.onNetworkSelected(event, NetworkTypes.ALL_NETWORKS, NetworkTypes.ALL_NETWORKS.id)}
        >
          <Link to={"/" + NetworkTypes.ALL_NETWORKS.link}>{NetworkTypes.ALL_NETWORKS.name}</Link>
        </MenuItem>
      )
    }

    // Main menu items generator, shows the network menu item first and all of its sites below it
    this.props.networkToSites.forEach((networkIdToSites, networkIndex) => {
      menuItemsToHtml.set(
        networkIdToSites.data[0].network.name + " " + networkIdToSites.data[0].network.id,
        <MenuItem
          className="dropdown-network"
          button
          key={"network-" + networkIdToSites.key}
          onClick={(event) => this.onNetworkSelected(event, networkIdToSites.data[0].network, networkIndex)}
        >
          <Link to={"/" + networkIdToSites.data[0].network.name + "/" + this.getPageLink()}>
            {networkIdToSites.data[0].network.name}
          </Link>
        </MenuItem>
      )
    })

    this.menuItemsToHtml = menuItemsToHtml
    this.rootMenuItems = Array.from(menuItemsToHtml.values())
  }

  handleClickButton = (event) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    })
  }

  navigationBarUpdated = () => {
    if (isAuthenticated()) {
      let firstNetwork = this.props.networkToSites[0].data[0].network

      if (isCampaignPopupOpenV2() || isAutomationPopupOpen()) {
        // If we're in a campaign popup page, we want to reset the network only in the case where there isn't one.
        // This can only happen if the user browsers directly to the campaign popup page.
        if (!this.props.currentNetwork) {
          this.props.dispatch(createNetworkSelected(firstNetwork))
          this.initRootMenu()
        }

        return
      }

      if (INDEPENDENT_PAGES.includes(this.props.currentPage)) {
        this.props.dispatch(createNetworkSelected(firstNetwork))
        this.initRootMenu()
        return
      }

      let isPermitted = true
      let permittedSite = null
      let permittedNetwork = null

      if (NETWORK_AND_SITES_PAGES.includes(this.props.currentPage)) {
        // If we're in one of these pages, we will check if the user is permitted according to the sites he has
        if (this.props.siteName) {
          permittedSite = getPermittedSite(this.props.sites, this.props.siteName, this.props.networkName)

          if (!permittedSite) {
            isPermitted = false
          } else {
            if (permittedSite.network.name !== this.props.networkName) {
              isPermitted = false
            }

            this.props.dispatch(createSiteSelected(permittedSite.network, permittedSite))
          }
        } else {
          permittedNetwork = getPermittedNetwork(this.props.networkToSites, this.props.networkName)

          if (!permittedNetwork) {
            isPermitted = false
          } else if (NetworkTypes.SPECIAL_NETWORK_IDS.includes(permittedNetwork.id)) {
            this.props.dispatch(createNetworkSelected(NetworkTypes.idToType[permittedNetwork.id]))
          } else {
            this.props.dispatch(createNetworkSelected(permittedNetwork.data[0].network))
          }
        }
      } else {
        isPermitted = false
      }

      // If the user is still not permitted, redirect him to his first network
      if (!isPermitted) {
        redirectToUrl("/" + firstNetwork.name)
        this.props.dispatch(createNetworkSelected(firstNetwork))
      }

      // Check permissions for the current page
      if (!AuthApi.hasModule("view." + this.props.currentPage)) {
        if (permittedNetwork) {
          if (permittedNetwork.data) {
            permittedNetwork = permittedNetwork.data[0].network
          }
        } else {
          permittedNetwork = firstNetwork
        }

        redirectToDefaultPage(permittedNetwork, permittedSite)
      }

      if (this.rootMenuItems.length === 0) {
        this.initRootMenu()
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.currentPage !== prevProps.currentPage ||
      this.props.networkName !== prevProps.networkName ||
      this.props.siteName !== prevProps.siteName
    ) {
      if (isAuthenticated()) {
        this.initRootMenu()
        this.navigationBarUpdated()
      }
    }
  }

  componentWillMount() {
    this.navigationBarUpdated()

    if (isAuthenticated()) {
      this.activateUpdates(this.props, true)
      this.props.dispatch(getAllActivitiesAsync())
    }
  }

  getCampaignManagementItems = () => {
    let campaignManagementLinks = []
    let campaignManagementItemsData = [
      { name: "Campaigns", url: Consts.CAMPAIGN_MANAGEMENT, containsPages: [Consts.CAMPAIGN_MANAGEMENT] },
      { name: "Rules", url: Consts.AUTOMATION_RULES, containsPages: [Consts.AUTOMATION_RULES] },
      { name: "Drafts", url: Consts.DRAFTS, containsPages: [Consts.DRAFTS] },
    ]
    campaignManagementItemsData.forEach((navItem) => {
      if (AuthApi.hasModule("view." + navItem.url)) {
        campaignManagementLinks.push(
          <MenuItem button key={navItem.name}>
            <Link to={"/" + this.props.networkName + "/" + navItem.url} onClick={this.closeCampaignManagementMenu}>
              {navItem.name}
            </Link>
          </MenuItem>
        )
      }
    })
    return AuthApi.hasModule("view." + Consts.CAMPAIGN_MANAGEMENT) ? (
      <div className="campaign-management-menu">
        <Button
          className={"campaign-management-menu-button"}
          aria-haspopup="true"
          onClick={this.handleClickCampaignManagementMenu}
        >
          Campaign Management
          {<KeyboardArrowDown className="arrow-down" />}
        </Button>
        <Menu
          classes={{ paper: "default-menu navigation-menu" }}
          id="campaign-management-menu"
          anchorEl={this.state.anchorElMenu}
          keepMounted
          open={Boolean(this.state.anchorElMenu)}
          onClose={this.closeCampaignManagementMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: 100,
          }}
          transformOrigin={{
            vertical: -5,
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          disableAutoFocusItem={true}
        >
          {campaignManagementLinks}
        </Menu>
      </div>
    ) : null
  }

  getArticlesItems = () => {
    let articlesLinks = []
    let articlesItemsData = [
      { name: "Content articles", url: Consts.ARTICLES, containsPages: [Consts.ARTICLES] },
      { name: "Search articles", url: Consts.ARTICLES_RSOC, containsPages: [Consts.ARTICLES_RSOC] },
    ]
    articlesItemsData.forEach((navItem) => {
      if (AuthApi.hasModule("view." + navItem.url)) {
        articlesLinks.push(
          <MenuItem button key={navItem.name}>
            <Link to={"/" + this.props.networkName + "/" + navItem.url} onClick={this.closeArticlesMenu}>
              {navItem.name}
            </Link>
          </MenuItem>
        )
      }
    })
    return AuthApi.hasModule("view." + Consts.ARTICLES) ? (
      <div className="campaign-management-menu">
        <Button
          className={"campaign-management-menu-button"}
          aria-haspopup="true"
          onClick={this.handleClickArticlesMenu}
        >
          Articles
          {<KeyboardArrowDown className="arrow-down" />}
        </Button>
        <Menu
          classes={{ paper: "default-menu navigation-menu" }}
          id="articles-menu"
          anchorEl={this.state.anchorElArticleMenu}
          keepMounted
          open={Boolean(this.state.anchorElArticleMenu)}
          onClose={this.closeArticlesMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: 100,
          }}
          transformOrigin={{
            vertical: -5,
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          disableAutoFocusItem={true}
        >
          {articlesLinks}
        </Menu>
      </div>
    ) : null
  }

  getNavigationItems = () => {
    if (isAuthenticated()) {
      let navigationItems = []
      let navigationItemsData = [
        { name: "Traffic Sources", url: Consts.TRAFFIC_SOURCES, containsPages: [Consts.TRAFFIC_SOURCES] },
        { name: "Dashboard", url: Consts.DASHBOARD, containsPages: [Consts.DASHBOARD] },
        { name: "Policy Review", url: Consts.POLICY_REVIEW, containsPages: [Consts.POLICY_REVIEW] },
      ]

      navigationItemsData.forEach((navItem) => {
        if (AuthApi.hasModule("view." + navItem.url)) {
          navigationItems.push(
            <Link
              key={navItem.url}
              className={
                "navigation-item " + (navItem.containsPages.includes(this.props.currentPage) ? "selected" : "")
              }
              to={"/" + this.props.networkName + "/" + navItem.url}
            >
              {navItem.name}
            </Link>
          )
        }
      })
      return navigationItems
    }

    return null
  }

  toggleDrawer = (event, drawerType) => {
    this.props.dispatch(navigationDrawerStateChange(!this.props.drawerIsOpen, drawerType))
  }

  activateUpdates = (props, updateNow = false) => {
    if (props.currentNetwork) {
      if (updateNow) {
        props.dispatch(getAllNotifications({ userId: props.user.id, networkId: props.currentNetwork.id }))
      }

      clearInterval(this.intervalId)
      this.intervalId = setInterval(() => {
        if (this.props.isBrowserTabActive) {
          let time = Moment().unix() - 30
          props.dispatch(
            getAllNotifications({ userId: props.user.id, fromTime: time, networkId: props.currentNetwork.id })
          )
        }
      }, Consts.NOTIFICATION_CHECK_INTERVAL)
    }
  }

  dismissNotification = (notification) => {
    this.props.dispatch(dismissAdminNotification(notification))
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  componentWillReceiveProps(nextProps) {
    let drawersWithoutUpdatesWhileOpen = []

    // If the network changes, we want to fetch its notifications
    if (JSON.stringify(this.props.currentNetwork) !== JSON.stringify(nextProps.currentNetwork)) {
      this.activateUpdates(nextProps, true)
    }

    if (this.props.drawerIsOpen === nextProps.drawerIsOpen) {
      return
    }

    if (nextProps.drawerIsOpen) {
      // Get all notifications when drawer is opened
      this.activateUpdates(nextProps, true)

      // Cancel updates while drawer is open on drawers that shouldn't be updated when opened (used to be activities)
      if (drawersWithoutUpdatesWhileOpen.includes(nextProps.currentDrawer)) {
        clearInterval(this.intervalId)
      }

      // Clearing the error messages if there were any
      this.props.dispatch(clearNotifications())
    } else if (drawersWithoutUpdatesWhileOpen.includes(this.props.currentDrawer)) {
      // If drawer is closed, re-activate the interval
      this.activateUpdates(nextProps, false)
    }
  }

  shouldComponentUpdate() {
    // When we're in a campaign popup location state, we don't want to re-render the navigation bar since we are currently not technically in a network route
    // (we're in /campaign/something, not a network route)
    return !isCampaignPopupLocationStateV2() && !isAutomationPopupLocationState()
  }

  render() {
    let drawer = null
    let backHomeBtn = null
    let navigationItems = null
    let notifications = []
    let dismissComp = null

    if (isAuthenticated()) {
      navigationItems = (
        <div className="navigation-items d-flex justify-content-end align-items-center">
          <NavigationIcons updates={this.props.updates} toggleDrawer={this.toggleDrawer} />
          <div
            className="user d-flex"
            ref={(node) => {
              this.button = node
            }}
            onClick={this.handleClickButton}
          >
            <Paper classes={{ root: "avatar-shadow" }} elevation={1} square={false}>
              <Avatar src={this.props.currentUser.picture} className="user-image" />
            </Paper>
            <div className="user-details">
              <div className="user-name">{this.props.currentUser.display_name}</div>
              <div className="user-site">
                {this.props.selectedItemName}
                {this.props.selectedItemName ? <KeyboardArrowDown className="arrow" /> : null}
              </div>
            </div>
          </div>
          <NavigationMenu
            searchCallback={this.updateSearch}
            search={this.state.search}
            closeMenu={this.closeMenu}
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            rootMenuItems={this.rootMenuItems}
            menuItemsToHtml={this.menuItemsToHtml}
            networkToSites={this.props.networkToSites}
            sites={this.props.sites}
          />
        </div>
      )

      drawer = (
        <Drawer
          classes={{ modal: "drawer-modal", paper: "drawer" }}
          anchor="right"
          open={this.props.drawerIsOpen}
          onClose={(event) => {
            this.toggleDrawer(event, this.props.currentDrawer)
            eventsTracker.trackDrawerClose(this.props.currentDrawer)
          }}
        >
          <div>
            <DrawerBody />
          </div>
        </Drawer>
      )

      if (this.props.adminNotifications) {
        this.props.adminNotifications.forEach((item, index) => {
          if (AdminNotificationMessageType.idToName[item.severity].isDismissable) {
            dismissComp = (
              <span className="dismiss-button clickable" onClick={() => this.dismissNotification(item)}>
                dismiss
              </span>
            )
          } else {
            dismissComp = null
          }
          notifications.push(
            <div
              className="single-notification"
              key={index}
              style={{
                backgroundColor: AdminNotificationMessageType.idToName[item.severity].color,
                borderBottom: AdminNotificationMessageType.idToName[item.severity].borderBottom,
              }}
            >
              <span
                style={{
                  fontSize: AdminNotificationMessageType.idToName[item.severity].fontSize,
                  textShadow: AdminNotificationMessageType.idToName[item.severity].textShadow,
                }}
                className="notification-text"
              >
                {item.message}
              </span>
              {dismissComp}
            </div>
          )
        })
      }
    } else {
      navigationItems = <div className="col-4 d-flex justify-content-end align-items-center" />
      backHomeBtn = (
        <div
          className="col-2 d-flex align-items-center"
          onClick={() => {
            window.location = "http://www.pubplus.com/"
          }}
        >
          <ArrowBack id="back-home-btn" />
          <span id="back-home-txt">Back home</span>
        </div>
      )
    }

    return (
      <AppBar
        classes={{ positionFixed: "appbar" }}
        style={{ marginBottom: 40 + this.ADMIN_NOTIFICATION_HEIGHT * notifications.length }}
      >
        <div className="admin-notification" style={{ height: this.ADMIN_NOTIFICATION_HEIGHT * notifications.length }}>
          {notifications}
        </div>
        <div className="navigation-bar" style={{ top: this.ADMIN_NOTIFICATION_HEIGHT * notifications.length }}>
          <div className="main-margins-1" />
          <div className="navigation-tabs d-flex justify-content-start align-items-center">
            <div onClick={() => redirectToDefaultPage(this.props.currentNetwork, this.props.currentSite)}>
              <PubPlusIcon className="logo" />
            </div>
            <div className="navigation d-flex">{this.getCampaignManagementItems()}</div>
            <div className="navigation d-flex">{this.getNavigationItems()}</div>
            <div className="navigation d-flex">{this.getArticlesItems()}</div>
          </div>
          {navigationItems}
          {backHomeBtn}
          <div className="main-margins-1" />
        </div>
        {drawer}
      </AppBar>
    )
  }
}

function mapStateToProps(state, ownProps) {
  let getSelectedItemName = (currentPage) => {
    if (NETWORK_AND_SITES_PAGES.includes(currentPage)) {
      return state.navigationBar.selectedItemName
    }

    switch (currentPage) {
      case "profile":
      case "campaignCreationSettings":
      case "account":
        return "Settings"
      default:
        return ""
    }
  }

  let firstNetworkName = state.app.networkToSites ? state.app.networkToSites[0].data[0].network.name : ""
  let networkName = ownProps.params.networkName ? ownProps.params.networkName : firstNetworkName
  let currentPage = ownProps.routes[ownProps.routes.length - 1].path

  if (currentPage) {
    let currentPageRouteParts = currentPage.split("/")
    currentPage = currentPageRouteParts[currentPageRouteParts.length - 1]
  }

  let selectedItemName = getSelectedItemName(currentPage)

  return {
    currentPage,
    networkName,
    selectedItemName,
    currentUser: state.app.currentUser,
    sites: state.app.sites,
    drawerIsOpen: state.navigationBar.drawer.isOpen,
    networkToSites: state.app.networkToSites,
    siteName: ownProps.params.siteName,
    user: state.app.currentUser,
    updates: state.navigationBar.drawer.updates,
    adminNotifications: state.navigationBar.adminNotifications.active,
    isBrowserTabActive: state.app.isBrowserTabActive,
    currentDrawer: state.navigationBar.drawer.drawerType,
    currentNetwork: state.navigationBar.currentNetwork,
    currentSite: state.navigationBar.currentSite,
  }
}

const connectedNavigationBar = connect(mapStateToProps)(NavigationBar)
export default withRouter(connectedNavigationBar)
