import React from "react"
import { connect } from "react-redux"
import CampaignPopupMainTop from "./mainTop"
import CampaignPopupPerformanceByDay from "./performanceByDay"
import CampaignPopupPerformanceBySubSources from "./performanceBySubSources"
import CampaignPopupPerformanceByCountry from "./performanceByCountry"
import CampaignPopupPerformanceByBrowser from "./performanceByBrowser"
import CampaignPopupBidHistory from "./bidHistory"
import Xicon from "../../resources/svgs/Xicon.svg"
import CampaignPopupPerformanceByHour from "./performanceByHour"
import CampaignPopupPerformanceByDevice from "./performanceByDevice"
import CampaignPopupPerformanceByFunnel from "./performanceByFunnel"
import CampaignPopupPerformanceByKeyword from "./performanceByKeyword"

class CampaignPopupMain extends React.Component {
  render() {
    let campaignMainContent = null
    switch (this.props.mainSubSection) {
      case "Day":
        campaignMainContent = <CampaignPopupPerformanceByDay />
        break
      case "Hour":
        campaignMainContent = <CampaignPopupPerformanceByHour />
        break
      case "SubSources":
        campaignMainContent = <CampaignPopupPerformanceBySubSources />
        break
      case "Country":
        campaignMainContent = <CampaignPopupPerformanceByCountry />
        break
      case "Funnel":
        campaignMainContent = <CampaignPopupPerformanceByFunnel />
        break
      case "Keyword":
        campaignMainContent = <CampaignPopupPerformanceByKeyword />
        break
      case "Browser":
        campaignMainContent = <CampaignPopupPerformanceByBrowser />
        break
      case "Device":
        campaignMainContent = <CampaignPopupPerformanceByDevice />
        break
      case "BidHistory":
        campaignMainContent = <CampaignPopupBidHistory />
        break
      default:
        campaignMainContent = <div>Unknown main sub-section</div>
    }

    return (
      <div className="campaign-popup-main">
        <Xicon
          className="campaign-popup-exit-button clickable"
          width="16"
          height="16"
          onClick={this.props.closeFunction}
        />
        <CampaignPopupMainTop />
        {campaignMainContent}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    campaign: state.campaignPopupV2.campaign,
    mainSubSection: state.campaignPopupV2.mainSubSection,
  }
}

export default connect(mapStateToProps)(CampaignPopupMain)
